// import React from 'react';
// import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// const phoneNumber = '+79252045775';
// const telegramUsername = 'jabrailofficial';
// const mail = '2045775@gmail.com';

// const handleTelegramClick = () => {
//   const telegramLink = `https://t.me/${telegramUsername.replace('@', '')}`;
//   window.open(telegramLink, '_blank');
// };

// const handleWhatsAppClick = () => {
//   const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber.replace('+', '')}`;
//   window.open(whatsappLink, '_blank');
// };

// const handleEmailClick = () => {
//   const emailLink = `mailto:${mail}`;
//   window.location.href = emailLink;
// };

// const ContactsInfo = {
//   phoneNumber,
//   handleTelegramClick,
//   handleWhatsAppClick,
//   handleEmailClick,
//   mail
// };

// export default ContactsInfo;
// ContactsInfo.js
import React from 'react';
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const phoneNumber = '+79203197404';
const telegramUsername = 'SmithsRussia';
const mail = '2045775@gmail.com';
const inst = 'https://www.instagram.com/smithbalmrussia?igsh=MWt4cWpiM3dpejljYQ%3D%3D&utm_source=qr'

const handleTelegramClick = () => {
  const telegramLink = `https://t.me/${telegramUsername.replace('@', '')}`;
  window.open(telegramLink, '_blank');
};

const handleWhatsAppClick = () => {
  const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber.replace('+', '')}`;
  window.open(whatsappLink, '_blank');
};

const handleEmailClick = () => {
  const emailLink = `mailto:${mail}`;
  window.location.href = emailLink;
};

const handleInstagramClick = () => {
  const instagramUsername = 'smithbalmrussia';
  const instagramLink = `https://www.instagram.com/${instagramUsername}`;
  window.open(instagramLink, '_blank');
};

const ContactsInfo = () => null; // Экспортируем функциональный компонент

ContactsInfo.phoneNumber = phoneNumber;
ContactsInfo.handleTelegramClick = handleTelegramClick;
ContactsInfo.handleWhatsAppClick = handleWhatsAppClick;
ContactsInfo.handleEmailClick = handleEmailClick;
ContactsInfo.mail = mail;
ContactsInfo.handleInstagramClick = handleInstagramClick;

export default ContactsInfo;
