// // import Contacts from "./Contacts";
// // import ContactsInfo from './ContactsInfo'
// // import './Styles.css'
// // function App() {

// //   const backgrounds = ['url(/balm.jpg)', 'url(/balm1.jpg)'];
// // let currentIndex = 0;

// // function changeBackground() {
// //   document.getElementById('background').style.backgroundImage = backgrounds[currentIndex];
// //   currentIndex = (currentIndex + 1) % backgrounds.length;
// // }

// // // Запуск функции смены фона каждые 5 секунд
// // setInterval(changeBackground, 5000);

// // // Изменить фон при загрузке страницы
// // document.addEventListener('DOMContentLoaded', changeBackground);

// //   return (
// //     <div className="App background">
// //       <h1>
// //         Приветствую Вас
// //       </h1>

// //       <h3>
// //         Пожалуйста, выберите удобный способ связи
// //       </h3>
// //       <div>
// //       <Contacts />
// //       </div>

// // {/* <ContactsInfo /> */}

// //     </div>
// //   );
// // }

// // export default App;
// import { useState, useEffect } from 'react';
// import Contacts from "./Contacts";
// import './Styles.css';
// import balm from './balm.jpg';
// import balm1 from './balm1.jpg';

// function App() {
//   const [backgroundIndex, setBackgroundIndex] = useState(0);

//   const backgrounds = [balm, balm1];

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
//     }, 5000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     //<div style={{ backgroundImage: `url(${backgrounds[backgroundIndex]})` } height:100vh}>

//     <div className='background'>
//     <div className="App">
//       <h1>
//         Приветствую Вас
//       </h1>

//       <h3>
//         Пожалуйста, выберите удобный способ связи
//       </h3>
      
//       <div>
//         <Contacts />
//       </div>
//     </div>
//     </div>
//   );
// }

// export default App;
import { useState, useEffect } from 'react';
import Contacts from "./Contacts";
import './Styles.css';
import balm from './balm.jpg';
import balm1 from './balm1.jpg';

function App() {
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  const backgrounds = [balm, balm1];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundIndex(prevIndex => (prevIndex + 1) % backgrounds.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="background" style={{ backgroundImage: `url(${backgrounds[backgroundIndex]})` }}>
      <div className="App">
        <div className='app1'>
        <h1>Приветствую Вас</h1>
        <h3>Пожалуйста, выберите удобный способ связи</h3>
        <div><Contacts /></div>
        </div>
      </div>
    </div>
  );
}

export default App;
