// import React from 'react';
//  import ContactsInfo from './ContactsInfo';
// import './Styles.css';
// import '../TotalParts/Contact.css'
// import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const Contacts = () => {
//   return (
//     <div className="content-container">
//       <div className="block">
//         <h2 className="text-centre">Контакты</h2>

//         <p>Телефон: <a href={`tel:${ContactsInfo.phoneNumber}`}>{ContactsInfo.phoneNumber}</a></p>
//         <button onClick={ContactsInfo.handleTelegramClick} className='menu-contact'><FontAwesomeIcon icon={faTelegram} className="icon-telegram" /></button>
//         <button onClick={ContactsInfo.handleWhatsAppClick}className='menu-contact'><FontAwesomeIcon icon={faWhatsapp} className="icon-whatsapp"/></button>
// <p>Почта: <a href={`mailto:${ContactsInfo.mail}`}>{ContactsInfo.mail}</a></p>
//         <button onClick={ContactsInfo.handleEmailClick}>Открыть почту</button>

//     </div>
//     </div>
//   );
// };

// export default Contacts;
// Contacts.js
import React from 'react';
import { faTelegram, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactsInfo from './ContactsInfo'; // Импортируем компонент
import './Styles.css';

const Contacts = () => {
  return (
    <div className="content-container">
      <div className="block">

        {/* <p>Телефон: <a href={`tel:${ContactsInfo.phoneNumber}`}>{ContactsInfo.phoneNumber}</a></p> */}
        <button onClick={ContactsInfo.handleTelegramClick} className='menu-contact'><FontAwesomeIcon icon={faTelegram} className="icon-telegram" /></button>
        <button onClick={ContactsInfo.handleWhatsAppClick} className='menu-contact'><FontAwesomeIcon icon={faWhatsapp} className="icon-whatsapp" /></button>
        <button onClick={ContactsInfo.handleEmailClick} className='menu-contact'><FontAwesomeIcon icon={faEnvelope} className="icon-mail" /></button>
        <button onClick={ContactsInfo.handleInstagramClick} className='menu-contact'><FontAwesomeIcon icon={faInstagram} className="icon-inst" /></button>
        {/* <p>Почта: <a href={`mailto:${ContactsInfo.mail}`}>{ContactsInfo.mail}</a></p> */}
        {/* <button onClick={ContactsInfo.handleEmailClick}>Открыть почту</button> */}

      </div>
    </div>
  );
};

export default Contacts;
